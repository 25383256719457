/* Contenedor Principal */
.container {
  display: flex;
  flex-direction: column;
  gap: 20px; 
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Subtítulo */
.subtitle {
  text-align: left;
  font-size: 14px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 15px;
}

/* Contenedor de Sidebar y Calendario */
.content {
  display: flex;
  flex-direction: row; 
  gap: 20px; 
  width: 100%;
}

/* Sidebar */
.sidebarContainer {
  flex: 1; 
  max-width: 300px; 
  min-width: 250px; 
}

/* Calendario */
.calendarContainer {
  flex: 3; 
  min-width: 600px; 
}


/* Tablets */
@media screen and (max-width: 768px) {

  .container{
    align-items: center;
  }
  
  .content {
    flex-direction: column;   
    gap: 15px; 
    align-items: center;
  }

  .sidebarContainer {
    max-width: 100%; 
    min-width: unset;
  }

  .calendarContainer {
    max-width: 100%; 
    min-width: unset;
  }

  .p{
    display: none;
  }
}

/* Teléfonos */
@media screen and (max-width: 480px) {
  .content {
    padding: 10px; 
  }

  .sidebarContainer {
    max-width: 100%; 
  }

  .calendarContainer {
    max-width: 100%; 
  }

  .subtitle{
    text-align: center;
  }
}
