.navbar {
    height: 4.5rem;
    background-color: var(--primary);
    width: 100%; 
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    box-sizing: border-box;
}

.logo {
    height: 2rem;
    width: auto;
}

.menu-items {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
}

nav ul {
    display: flex;
    flex-flow: row nowrap;
    gap: 2rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav ul a, .nav-link {
    color: var(--light);
    text-decoration: none;
    font-weight: 400;
}

.mobile-toggle {
    position: absolute;
    top: 0;
    opacity: 0;
    pointer-events: none;
}

.mobile-toggle button {
    background-color: transparent;
    color: var(--light);
    border: none;
    cursor: pointer;
}

.mobile-menu {
    position: absolute;
    width: calc(70vw - 4rem); 
    min-width: 220px; 
    height: calc(100vh - 4rem);
    background-color: var(--primary-light);
    top: 0;
    right: 0;
    transition: 300ms;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;
    align-items: flex-start;
    justify-content: flex-start;
    opacity: 1;
}

.mobile-menu button {
    margin-left: auto;
    border: none;
    background-color: transparent;
    color: var(--light);
    cursor: pointer;
}

.mobile-menu ul {
    flex-flow: column nowrap;
    padding-left: 0;
    margin: 0;
}

.mobile-menu li a {
    font-weight: 500;
}

.mobile-profile {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
}

.mobile-profile a {
    color: var(--light);
    font-weight: 500;
    text-decoration: none;
}

.menu-overlay {
    position: fixed; 
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: 300ms;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

.MuiAvatar-root {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.mobile-menu p,
.mobile-menu a {
    color: var(--light);
    font-weight: 500;
}

.logout-mobile {
    color: var(--dark);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    text-decoration: none;
    cursor: pointer;
}

.divider {
    width: 1px;
    height: 2.4rem;
    background-color: var(--light);
    margin: 0 1rem;
}

.avatar-container {
    display: inline-flex; 
    align-items: center;
    cursor: pointer;
}

.user-name {
    color: var(--light);
    font-size: 0.9rem;
    margin-left: 0.5rem; 
}

.menu-overlay {
    z-index: 900;
}

.mobile-menu {
    z-index: 1001; 
}

@media (max-width: 400px) {
    .mobile-menu {
        width: calc(85vw - 4rem); /* Ancho responsivo reducido */
        min-width: 220px; /* Asegura el ancho mínimo */
    }
}

@media (max-width: 765px) {
    .menu-items {
        display: none !important; 
    }

    .mobile-toggle {
        position: relative;
        opacity: 1;
        pointer-events: all;
    }
}

@media (max-width: 400px) {
    .mobile-menu {
        width: calc(85vw - 4rem);
    }
}

