.app {
    display: flex;
    flex-direction: column;
    height: 185vh;
    width: 184vh;
    background-color: #005280;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #005280;
    flex-wrap: wrap;
}

.actions {
    display: flex;
    align-items: right;
    gap: 12px;
}

.icon {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.title {
    margin-left: 20px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding-right: 20px;
}

.container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cono {
    position: relative;
    width: 100%;
    max-width: 1220px;
    height: 1346px;
    margin: 0 auto;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.flecha {
    position: absolute;
    height: 6px;
    background-color: transparent;
    z-index: 2;
    overflow: visible !important;
}

.nodo {
    position: absolute;
    padding: 4px 11px;
    border-radius: 12px;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 4; 
    font-weight: 400;
    text-align: center;
    z-index: 3;
    font-size: 10px;
    max-width: 80px;
    min-width: 80px;
    min-height: 40px;
    max-height: 50px;
    align-content: center;
    overflow: hidden; 
    text-overflow: ellipsis; 
    word-wrap: break-word; 
    white-space: normal; 
}

.ambicioso {
    background-color: #EFCE2A;
    color: #000;
}

.adyacente {
    background-color: #A4CBF4;
    color: #000;
}

.adyacenteii {
    background-color: #A4CBF4;
    color: #000;
}

.directo {
    background-color: #26D07C;
    color: #000;
}

.divergente {
    background-color: #E592BA;
    color: #000;
}

.puesto {
    background-color: #FFFFFF;
    color: #000;
}


.textoEscenario {
    position: absolute;
}

.subtextoEscenario {
    max-width: 190px;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    text-align: center;
    font-size: 14px;
}

.puntaFlecha {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid white;
    position: absolute;
}

.scrollHintContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

/* Líneas Horizontales (Superior e Inferior) */
.lineHorizontal {
    position: absolute;
    width: 90%;
    height: 2px;
    background-color: #ffffff;
    left: 5%;
}

.lineTop {
    top: 5%;
}

.lineBottom {
    bottom: 5%;
}

/* Líneas Verticales (Izquierda y Derecha) */
.lineVertical {
    position: absolute;
    width: 2px;
    height: 80%;
    background-color: #ffffff;
    top: 10%;
}

.lineLeft {
    left: 5%;
}

.lineRight {
    right: 5%;
}

.scrollHintContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9999;
    opacity: 1;
    transition: opacity 3s ease-in-out; 
}

.fadeOut {
    opacity: 0;
}


.corner {
    position: absolute;
    width: 50px;
    height: 50px;
}

.cornerTopLeft {
    top: 11%;
    left: 5%;
    border-left: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
}

.cornerBottomRight {
    bottom: 10%;
    right: 4%;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
}

.scrollHintTop,
.scrollHintBottom {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    padding: 8px 0;
    z-index: 9999;
}

.scrollHintTop {
    top: 12%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.scrollHintBottom {
    bottom: 11%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


@media screen and (max-width: 768px) {
    .header {
        flex-direction: column;
    }
}