  /* Estilos generales de la sidebar */
  .sidebar {
    width: 16rem;
    padding: 1.5rem 1.5rem 2rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    background-color: var(--white);
    font-family: "ibm plex sans", "Roboto", sans-serif;
    border: 1px solid var(--gray);
    border-radius: 30px;
    transition: width 0.4s ease, padding 0.4s ease;
  }

  .sidebar p {
    margin: 0;
    line-height: 1.15;
  }

  /* Título de la sidebar */
  .sidebar-title {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .sidebar-title p {
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--dark);
  }

  .sidebar-buttons {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .step {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    opacity: 1;
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
    transition: padding 0.6s ease, height 0.6s ease;
  }

  .step-complete {
    cursor: pointer;
  }

  .step-complete p {
    color: var(--dark);
  }

  .step-complete:hover {
    text-decoration: underline;
    color: var(--primary);
  }

  .current {
    font-weight: 600;
  }

  .index {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    width: 2rem;
    height: 2rem;
  }

  .index-complete {
    background-color: var(--dark);
    color: white;
    cursor: pointer;
  }

  .index-pending {
    background-color: var(--input-gray);
    color: var(--light);
  }

  .index-pending p {
    color: var(--light-gray);
  }

  .step p {
    width: calc(100% - 3rem);
    color: var(--dark);
  }

  /* Estilos para la versión colapsada */
  @media (max-width: 765px) {
    .sidebar {
      width: 100%;
      padding: 1.5rem 1.5rem 2rem;
      transition: width 0.4s ease, padding 0.4s ease;
    }
  
    /* Cuando la sidebar está colapsada en mobile */
    .sidebar.collapsed {
      width: 100%;
      height: 3rem; 
      padding: 0.8rem 1.2rem; 
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
    }
  
    .sidebar.collapsed .sidebar-title p {
      font-size: 1rem; 
    }
  
    .sidebar.collapsed .step {
      display: none; 
    }
  
    .menu-items, nav, ul {
      max-width: 100vw !important;
      overflow-x: hidden;
    }
  }
  

  @media (max-width: 765px) {
    .sidebar {
      width: 100%;
      padding: 1.5rem 1.5rem 2rem;
      transition: width 0.4s ease, padding 0.4s ease;
    }
  }

  @media (max-width: 768px) {
    .sidebar.collapsed {
      width: 63%;
      height: 3rem;
    }

    .sidebar-title {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 10%;
      padding-top: 2%;
    }
  }