/*Se integró del jsx correctamente*/
.plusbutton {
    position: relative;
    display: "inline-block"; 
    top: 3rem;
    z-index: 10; 
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

/*Creación de nueva clase para integrar lo del jsx*/
.buttonstyle {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px solid var(--chip-color);
    background-color: #ccc;
}

.puesto-text {
    position: absolute;
    top: -29px;  
    color: #FFFFFF;
    font-weight: normal;
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    left: 49.2%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.details-content {
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.details-content.open {
    animation: slideDown 0.3s ease forwards;
}

.details-content.close {
    animation: slideUp 0.3s ease forwards;
}

/*Se integró del jsx correctamente*/
.animated-li {
    padding-left: 9px;
    flex-wrap: wrap;
    list-style-type: none;
    margin-top: 16px;
    overflow: hidden;
}

/*Se integró del jsx correctamente*/
.container-animated {
    max-width: 70rem;
    min-height: 35rem;
    border-radius: "8px";
    position: "relative"; list-style-type: none;
    margin-top: 16px;
    overflow: "hidden";
    padding: "36px";
    z-Index: 0;
}

/*Creación de nueva clase para integrar lo del jsx*/
.background-blue {
    padding: 8px;
    background-color: #005280;
    border-radius: 8px;
    white-space: nowrap;
}

/*Se integró del jsx correctamente*/
.lines {
    bottom: -27%;
    position: absolute;
    left: 49.6%;
    width: 3px;
    background-color: #FFFFFF;
    transform: translateX(-50%);
    z-index: 1;
    height: 100px;
}

/*Creación de nueva clase para integrar lo del jsx*/
.line-long {
    position: absolute;
    bottom: -100%;
    left: 48.4%;
    width: 3px;
    height: 100%;
    background-color: #FFFFFF;
    transform: translateX(-50%);
    z-index: 0;
}

/*Creación de nueva clase para integrar lo del jsx*/
.line-short {
    position: absolute;
    bottom: -20px;
    left: 48.1%;
    width: 3px;
    height: 40px;
    background-color: #FFFFFF;
    transform: translateX(-50%);
    z-index: 0;
}

/*Se integró del jsx correctamente*/
.ul-node {
    overflow: visible;
    padding: 0px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/* Nueva clase para integrar jsx */
.ul-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 12px;
    z-index: 1;
}

.escenario-add {
    position: absolute;
    top: -22px;
    right: -60px;
    left: auto;
    z-index: 100;  
    border-radius: 8px;
    z-index: 3;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s ease;
    white-space: nowrap;
    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-items: center;
}

.escenario-add .options {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    white-space: nowrap; 
}

.escenario-add.open .options {
    display: block;
}

/* Nueva clase para integrar jsx */
.animated-li-container {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

/* Nueva clase para integrar jsx */
.add-escenario-text {
    padding: 6px 12px;
    background-color: transparent;
    color: #C6C7C8;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Nueva clase para integrar jsx */
.add-ambicioso-text {
    padding: 6px 12px;
    background-color: transparent;
    color: #C6C7C8;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Nueva clase para integrar jsx */
.is-puesto-node {
    border: none;
    outline: none;
    background: transparent;
    text-align: center;
    font-size: 12px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Nueva clase para integrar jsx */
.filled-styles {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

/* Nueva clase para integrar jsx */
.remove-button {
    position: absolute;
    top: -10px;
    left: 88%;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    z-index: 2;
}

/* Nueva clase para integrar jsx */
.arrowhead-line {
    position: absolute;
    bottom: -8px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #FFFFFF;
    transform: translateX(-50%);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
        /* Aparece desde arriba */
    }

    to {
        opacity: 1;
        transform: translateY(0);
        /* Posición normal */
    }
}

.node {
    animation: fadeIn 0.3s ease-in-out;
    /* Animación de entrada */
}

@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
    }

    to {
        max-height: 500px;
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        max-height: 500px;
        opacity: 1;
    }

    to {
        max-height: 0;
        opacity: 0;
    }
}

@media screen and (max-width: 768px) {
    .ul-node {
        overflow-x: visible !important;
        overflow-y: visible !important;
        max-height: none !important;
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
        width: 100%; 
        margin: 0 !important;
        padding: 0 !important; 
    }
    
    .puesto-text {
        position: relative;  
        display: block;
        text-align: center;
        font-size: 16px; 
        margin-top: -10px;
        width: 100%;  
        top: auto;  
        left: 51.2%;  
        transform: translateX(-50%); 
    }


    .animated-details {
        width: 95%;
        padding: 10px;
    }

    .node {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .chip-container {
        width: 100%;
    }

    .line {
        width: 100%;
        height: 500px;
    }

    .lines {
        position: absolute;
        margin-top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 3px;
        z-index: 1;
        height: 122px;
    }

    .line-long {
        position: absolute;
        bottom: -100%;
        left: 47.5%;
        width: 3px;
        height: 100%;
        background-color: #FFFFFF;
        transform: translateX(-50%);
        z-index: 0;
    }
    
    .line-short {
        position: absolute;
        bottom: -20px;
        left: 47.5%;
        width: 3px;
        height: 40px;
        background-color: #FFFFFF;
        transform: translateX(-50%);
        z-index: 0;
    }

    .ul-node li {
        display: flex;
        flex-direction: column;
        align-items: center; 
        position: relative;
        width: 100%; 
    }

    .dropdown-button {
        width: 100%;
        justify-content: center;
    }

    .animated-li {
        flex-flow: row;
    }

    .escenario-add {
        position: absolute;
        top: -20px;
        transform: translateX(50%); 
        width: auto; 
        z-index: 100;
    }

    .container-animated {
        overflow: visible !important;
        width: 100%; 
        max-width: 100%;
        min-height: 35rem; 
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .plusbutton {
        top: 2.5rem;
    }

    .puesto-text {
        position: relative;
        text-align: center;
        font-size: 14px;
        margin-top: -10px;
        width: 100%;  
    }
}