@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --primary: #005280;
  --primary-light: #3F7DA0;
  --primary-lighter: #A1DCDC;
  --secondary: #E4F6EA;
  --secondary-dark: #A0DBDC;
  --light: #FBFBFC;
  --white: #FFFFFF;
  --dark: #141619;
  --gray-dark: #75767A;
  --gray: rgb(216,216,216);
  --light-gray: #C6C7C8;
  --input-gray: #737E98;
  --gray-subtitle: #4d5154;
  --error-text: #cc0000;
  --error-bg: #ffcccc;
  --success-text: #00534C;
  --success-bg: #E4F6EA;
  --dark-green: #00534C;
  --bright-green: #26D07C;
  --blue: #1EC6BD;
  --green: #00A499;
  --light-green: #64CD84;
  --light-blue: #F2F8FA;
}

html, body {
  width: 100% !important;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: "ibm plex sans", "Roboto", sans-serif;
  background-color: var(--light);
  overflow-x: hidden;
}

.placeholder {
  width: 100%;
  background-color: #DDDDDD;
  flex: 1 1 auto;
  height: calc(100vh - 4.5rem - 4rem);
}