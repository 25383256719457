.container {
    border-radius: 12px;
    width: 100%; 
    max-width: 30rem; 
    box-sizing: border-box; 
    overflow-x: hidden; 
    margin: 0 auto; 
    position: relative;
    padding-top: 40px; 
}

.subcontainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0px;
    gap: 10px;
}

.buttons{
    padding: 10px 20px;
    font-size: 14px;
    font-weight: lighter;
    border: none;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
}

.activeButton{
    width: 100%;
    height: 3px;
    background-color: #FFFFFF;
    margin-top: 6px;
    border-radius: 2px;
}

.innerContainer{
    position: relative; 
    margin-top: 0px; 
    padding-top: 0px;
}

.subInnerContainer {
    position: relative;
    max-height: 100rem;
    opacity: 1;
    overflow: visible;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    align-content: center;
    z-index: 5;
}

@media (min-width: 768px) {
    .container {
        border-radius: 16px;
        width: 100%;
        min-width: 55rem;
        margin: 0 auto; 
    }

    .innerContainer {
        max-height: 120rem;
    }
    
}

@media (max-width: 768px) {
    .container {
        border-radius: 16px;
        width: 30%;
        min-width: 29rem;
        margin: 0 auto; 
    }

    .innerContainer {
        max-height: 120rem;
        max-width: 70%;
    }

    .subcontainer {
        display: flex;
        gap: 3px;
    }

    .buttons{
        padding: 10px 12px;
        height: 52px;
        width: 17%;
        font-size: 11px;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
}

